<template>
	<div>
		<el-card class="filter-container" shadow="never">
			<el-form ref="form" :model="form"  :rules="dataRule"  label-width="80px">
				<el-form-item label="所属位置" prop="plugAdAdtypeid">
					 <el-select v-model="form.plugAdAdtypeid" value-key="plugAdtypeName" filterable placeholder="请选择" style="width: 30%;">
					    <el-option
					      v-for="item in plugAdTypeList"
					      :key="item.plugAdtypeName"
					      :label="item.plugAdtypeName"
					      :value="item.plugAdtypeId">
					    </el-option>
					  </el-select>
				</el-form-item>

				<el-form-item label="广告名称"  prop="plugAdName">
					<el-input v-model="form.plugAdName" style="width: 30%;"></el-input>
				</el-form-item>

				<el-form-item label="链接URL">
					<el-input v-model="form.plugAdUrl" style="width: 30%;"></el-input>
				</el-form-item>
				
				
				
				
				<el-form-item label="是否开启">
					<el-switch v-model="form.plugAdOpen" >
					</el-switch>
				</el-form-item>
				
			

				<el-form-item label="排序">
                    <el-input v-model="form.plugAdOrder" style="width: 30%;" onkeyup="value=value.replace(/[^\d]/g,'')" onafterpaste="value=value.replace(/\D/g,'')"></el-input>
                    <font color="red" style="margin-left: 10px">请输入整型数字!!</font>
				</el-form-item>

				<el-form-item label="图片">
					<el-upload class="upload-demo" :action="uploadImgUrl" style="width: 30%;"
					 :on-success="onsuccess" list-type="picture" :multiple="multiple" :limit="1" :on-exceed="onexceed" :file-list="fileList"
					 :on-remove="onremove">
						<el-button size="small" type="primary">点击上传</el-button>
					</el-upload>
				</el-form-item>

				<el-form-item label="内容">
					<el-input v-model="form.plugAdContent" style="width: 30%;"></el-input>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="onSubmit">确定</el-button>
					<el-button type="info" @click="gobreak">取消</el-button>
				</el-form-item>
			</el-form>
		</el-card>

	</div>


</template>
<script>
	export default {
		data() {
			return {
                form: { //点击选中的
                    plugAdId:'',
                    plugAdAdtypeid: '',//广告所属位置
                    plugAdName:'',//广告名称
                    plugAdUrl:'',//链接跳转地址
                    plugAdOpen:false, //开关
                    plugAdOrder:'',//排序
                    plugAdContent:'',//内容
                    plugAdCheckid:1,
                    plugAdJs:'',
                    plugAdL: "zh-cn"
                },
				imageUrl: '',
				multiple: false, //多文件上传
				query:[],//路径带参
				plugAdTypeList:[],
				datails:[],
				fileList:[],
				uploadImgUrl: '', // 图片上传路径
				newsImg: '', //图片url
                dataRule: {
                    plugAdAdtypeid: [
                        { required: true, message: '所属位置不能为空', trigger: 'change' }
                    ],
                    plugAdName: [
                        { required: true, message: '广告名称不能为空', trigger: 'blur' }
                    ]
                },
			}
		},
		methods: {
			onSubmit() {
				const that=this;
                let apiUrl;
                that.$refs['form'].validate((valid) => {
                    if (valid) {
                        if(that.form.plugAdOpen){
                            that.form.plugAdOpen = '1';
                        }else{
                            that.form.plugAdOpen = '0';
                        }
                        that.form.plugAdPic = that.newsImg;

                        if( that.query.plugAdId ){
                            apiUrl='/admin/plug/edit';
                            that.form.plugAdId=that.query.plugAdId;
                        }else{
                            apiUrl='/admin/plug/add';
                        }
                        that.api(apiUrl,that.form).then(res=>{
                            if(res.code==200){
                                that.$router.replace({name:"plugadlist"});
                            }
                        })
                    }
                })
			},
			// 获取详情
			getlist(){
				const that=this;
				that.api('/admin/plug/getEditItem',{plugAdId:that.query.plugAdId}).then(res=>{
					that.datails=res.data.plugAd;
                    that.form.plugAdName=res.data.plugAd.plugAdName;
                    that.form.plugAdUrl=res.data.plugAd.plugAdUrl;
                    that.form.plugAdOrder=res.data.plugAd.plugAdOrder;
                    that.form.plugAdContent=res.data.plugAd.plugAdContent;
                    that.form.plugAdAdtypeid=Number(res.data.plugAd.plugAdAdtypeid);
                    that.form.plugAdOpen=res.data.plugAd.plugAdOpen == '1' ? true : false;
					that.newsImg=res.data.plugAd.plugAdPic;
					
					
					if(res.data.plugAd.plugAdPic!=''){
						var imgshow={
							url:that.$utils.apiurl+res.data.plugAd.plugAdPic
						}
						
						that.fileList.push(imgshow)
					}
					
				})
			},
			// 获取所属位置
			getplugAdTypeList(){
				const that=this;
				that.api('/admin/plug/adtype_list').then(res=>{
					that.plugAdTypeList=res.data.pageList;
					
				})
			},
			// 返回上一页
			gobreak(){
				const that=this;
				this.$router.go(-1);//返回上一层
			},
			
			// 图片上传成功
			onsuccess(file, fileList) {
				const that = this;
				that.newsImg=file.data.filePath;
				
			},
			
			
			// 点击删除
			onremove(file, fileList) {
				const that = this;
				that.newsImg='';
			},
			
			// 图片上传超出
			onexceed(e) {
				const that = this;
				this.$message({
					showClose: true,
					message: '数量超出限制',
					type: 'warning'
				});
			
			},
			
			// 返回上一页
			gobreak(){
				const that=this;
				this.$router.go(-1);//返回上一层
			},
			// 点击上传图片
			successimg(e){
				
			},
			// 点击删除
			handleRemove(){
				
			},
			
			// 监听所属位置改变
			// plugAdtypebtn(){
			// 	const that=this;
			// 	console.log(that.plugAdAdtypeid)
			// }
		},
		created() {
			const that=this;
			that.query=that.$route.query;
			that.getplugAdTypeList();
			that.uploadImgUrl = that.$utils.uploadimgurl;
			if(that.query.plugAdId){
				that.getlist();
			}
		}
	}
</script>

<style scoped lang="less">
</style>
